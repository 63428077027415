import React from "react"
import Container from "../../components/Layout/Container"
import "./Overview.scss"
import PodcastsGrid from "../Grid/PodcastsGrid"

function PodcastsOverview() {
  return (
    <Container page="Podcasts">
      <div className="podcasts container">
        <PodcastsGrid />
      </div>
    </Container>
  )
}

export default PodcastsOverview
