import React from "react"
import { Router } from "@reach/router"
import PodcastsOverview from "../components/Podcasts/Overview"
import PodcastDetail from "../components/Podcasts/Detail"
import PodcastEpisodeDetail from "../components/Podcast-Episode/Detail"

const Podcasts = () => {
  return (
    <Router>
      <PodcastsOverview path="podcasts" />
      <PodcastDetail path="podcasts/:slug" />
      <PodcastEpisodeDetail path="podcasts/:slug/:slug" />
    </Router>
  )
}

export default Podcasts
