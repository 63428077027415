import React, { useState, useEffect } from "react"
import { useGuestApi } from "../../hooks/useApi"
import {
  convertToSlug,
  convertToSlugWithOrigin,
  getColor,
  getInstagramImage,
  getModelType,
  getSingleImage,
  getTitle,
} from "../../helpers/Publications"
import { getImageType } from "../../helpers/Prepr"
import Card from "../Card/Card"
import HomeModal from "../Modal/HomeModal"
import { formatPageInformation } from "../../helpers/Meta"
import { useLocation } from "@reach/router"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 30,
}

export default function PodcastsGrid() {
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(initialPagination)
  const [page, setPage] = useState(1)
  const { getPodcastShowPublications } = useGuestApi()
  const [podcasts, setPodcasts] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(fetchProgramsPublications, [page])

  function fetchProgramsPublications() {
    getPodcastShowPublications(pagination).then(response => {
      setPagination({
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      })
      setPodcasts(podcasts.concat(response.grid.items))
      setLoading(false)
    })
  }

  function loadMore() {
    setIsLoading(true)
    if (podcasts.length < pagination.total) {
      setPagination({
        total: pagination.total,
        limit: pagination.limit,
        skip: podcasts.length,
      })
      setPage(page + 1)
      setIsLoading(false)
    }
  }

  return (
    <div className="podcasts__grid">
      {podcasts.map((item, i) => {
        return (
          <Card
            key={`podcast-${i}`}
            type="podcast"
            title={getTitle(item.title)}
            image={getSingleImage(
              getImageType(
                "image_website_block",
                item.image,
                null,
                item.image_website_block,
                null
              )
            )}
            instagramImage={getInstagramImage(item.instagram_post)}
            color={getColor(item.color)}
            route={convertToSlug(item.model_id, item.slug)}
          />
        )
      })}
    </div>
  )
}
