import React, { useState, useEffect, useMemo } from "react"
import { MainContentLoading } from "../../components/Layout/MainContent"
import "./Detail.scss"
import { useGuestApi } from "../../hooks/useApi"
import withGuestLayout from "../../hoc/withGuestLayout"
import { formatPageInformation } from "../../helpers/Meta"
import Error404 from "../Errors/404"
import PodcastHeader from "./Header"
import PodcastEpisode from "./Episode"
import Select from "../Forms/Select"
import { getShowEpisodes } from "../../helpers/Publications"

function PodcastDetail({ slug }) {
  const { getPodcastShowPublicationBySlug, setPageInformation } = useGuestApi()
  const [podcastItem, setPodcastItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [sorting, setSorting] = useState("new-old")

  const showEpisodes = useMemo(() => (getShowEpisodes(podcastItem?.show_episodes) && podcastItem.podcasts?.length > 0), [podcastItem])

  useEffect(
    function fetchPublications() {
      getPodcastShowPublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response))
        setPodcastItem(response)
        if (window.opix && response) {
          opix("event", "View", { publication: response.id })
        }
      })
    },
    [slug]
  )
  return (
    <>
      {loading && <MainContentLoading />}
      {podcastItem && (
        <div className="podcast__detail container">
          <PodcastHeader showEpisodes={showEpisodes} {...podcastItem} />
          {showEpisodes &&
            <div className="podcast__episodes">
              <div className="podcast__episodes-header">
                <h3 className="podcast__episodes-title">Afleveringen</h3>
                <Select
                  options={[
                    {
                      value: "new-old",
                      label: "Nieuw naar oud",
                    },
                    {
                      value: "old-new",
                      label: "Oud naar nieuw",
                    },
                  ]}
                  onChange={e => {
                    setSorting(e.target.value)
                  }}
                />
              </div>
              <ul className="podcast__episodes-list">
                {podcastItem.podcasts
                  .sort((a, b) =>
                    sorting === "new-old"
                      ? Date.parse(b.publish_on) - Date.parse(a.publish_on)
                      : Date.parse(a.publish_on) - Date.parse(b.publish_on)
                  )
                  .map((item, i) => {
                    return (
                      <li className="podcast__episodes-item" key={`show-${i}`}>
                        <PodcastEpisode
                          count={
                            sorting === "new-old"
                              ? podcastItem.podcasts.length - i
                              : i + 1
                          }
                          {...item}
                        />
                      </li>
                    )
                  })}
              </ul>
            </div>
          }
        </div>
      )}
      {!podcastItem && !loading && <Error404 />}
    </>
  )
}

export default withGuestLayout(PodcastDetail, {
  page: "Podcast",
})
